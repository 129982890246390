//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralEdit from '@/components/GeneralEdit';
export default {
  name: 'Index',
  components: {
    GeneralEdit: GeneralEdit
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      rules: {}
    };
  },
  computed: {
    url: function url() {
      if (this.$route.params.id) {
        return {
          getUrl: '/city',
          method: 'put'
        };
      } else {
        return {
          getUrl: '/city',
          method: 'post'
        };
      }
    }
  }
};